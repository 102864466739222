import { requestHandler } from "../../utils/requestHandler";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import CryptoJS from "crypto-js";
import { getripayUrl, getrisaveUrl, version } from "../../config";
import axios from "axios";
import { getPayload } from "../../utils/encrypt";

const register_token = sessionStorage.getItem("register_token");
const email = sessionStorage.getItem("email");
const user_id = sessionStorage.getItem("user_id");

const redirect = (route) => {
	window.location.href = route;
};

export const publicKey = createAsyncThunk(
	"login/publicKey",
	async (data, { rejectWithValue }) => {
		try {
			const response = await axios.get(
				"https://getripay.com/public.key",
				{
					headers: {
						"Content-Type": "application/json",
						"Access-Control-Allow-Origin": "*",
					},
				}
			);
			sessionStorage.setItem("publicKey", response.data);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const signIn = createAsyncThunk(
	"login/signIn",
	async (data, { rejectWithValue, getState }) => {
		try {
			const { publicKey } = getState().login;

			let passPayload = getPayload(publicKey, data, version);

			const response = await requestHandler.post(
				`/user/users/login`,
				passPayload
			);
			sessionStorage.setItem("email", response.data.data.email);
			sessionStorage.setItem(
				"register_token",
				response.data.data.register_token
			);
			sessionStorage.setItem("user_id", response.data.data.user_id);
			sessionStorage.setItem("answer_id", response.data.data.answer_id);
			if (response.data.response_code === "USM006") {
				if (response.data.data.preferred_2fa === "GA") {
					toast.success(
						"Success. Kindly input the OTP from your authenticator app"
					);
					redirect("/verify/google");
				} else {
					toast.success(
						"Success. Kindly provide an answer to your security question below "
					);
					redirect("/verify/security-question");
				}
			} else if (response.data.response_code === "USM004") {
				toast.error("Your account has not been verified");
				redirect("/verify/phone");
			}
			return response.data;
		} catch (error) {
			if (error.response.data.response_message === "Incorrect password") {
				toast.error("Incorrect Credentials");
			} else if (error.response.data.response_code === "USM012") {
				toast.error("You do not have an account, please register");
			} else if (error.response.data.response_code === "USM027") {
				toast.error("You have not set your security question");
				sessionStorage.setItem(
					"user_id",
					error.response.data.response_message.user_id
				);
				sessionStorage.setItem(
					"email2",
					error.response.data.response_message.email
				);
				redirect("/account-setup");
			} else if (error.response.data.response_code === "USM031") {
				toast.error(error.response.data.data);
			} else {
				toast.error("ERROR");
			}

			return rejectWithValue(error.response.data);
		}
	}
);

export const verifySecurityLogin = createAsyncThunk(
	"login/verifySecurityLogin",
	async ({ answer, answer_id }, { rejectWithValue, getState }) => {
		try {
			const { publicKey } = getState().login;

			let passPayload = getPayload(
				publicKey,
				{ register_token, email, user_id, answer_id, answer },
				version
			);

			const response = await requestHandler.post(
				`/user/users/login-web-question-answer-verify`,
				passPayload
			);

			sessionStorage.setItem("user_id", response.data.data.id);
			sessionStorage.setItem("token", response.data.data.token);
			sessionStorage.setItem(
				"account",
				response.data.data.bank_account[0].account_number
			);
			sessionStorage.setItem(
				"acc_name",
				response.data.data.bank_account[0].account_name
			);
			sessionStorage.setItem("kyc", response.data.data.kyc_level);

			if (response.data.response_code === "USM003") {
				const appType = sessionStorage.getItem("applicationType");
				toast.success("Login Successful");
				var ciphertext = CryptoJS.AES.encrypt(
					JSON.stringify({
						token: response.data.data.token,
						kyc: response.data.data.kyc_level,
						user_id: response.data.data.id,
						account:
							response.data.data.bank_account[0].account_number,
						acc_name:
							response.data.data.bank_account[0].account_name,
						keys: {
							aes_key: response.data.data.aes_key,
							aes_iv: response.data.data.aes_iv,
						},
					}),
					appType
				).toString();

				if (appType === "getrisave") {
					window.location.href = `${getrisaveUrl}?token=${ciphertext}`;
				} else {
					window.location.href = `${getripayUrl}?token=${ciphertext}`;
				}
			}

			return response.data;
		} catch (error) {
			if (error.response.data.response_code === "USM008") {
				toast.error("Token has expired, please login again");
				redirect("/");
			} else if (error.response.data.response_code === "USM009") {
				toast.error("Invalid Login code");
			} else if (error.response.data.response_code === "USM026") {
				sessionStorage.setItem(
					"answer_id",
					error.response.data.response_message.answer_id
				);
				toast.error(
					"Incorrect Answer. Please answer the next question"
				);
			}
			return rejectWithValue(error.response.data);
		}
	}
);

export const verifyGoogle = createAsyncThunk(
	"login/verifyGoogle",
	async (one_time_password, { rejectWithValue, getState }) => {
		try {
			const { publicKey } = getState().login;

			let passPayload = getPayload(
				publicKey,
				{ email, one_time_password, register_token },
				version
			);

			const response = await requestHandler.post(
				`/user/users/validate_google_auth_code`,
				passPayload
			);
			sessionStorage.setItem("user_id", response.data.data.id);
			sessionStorage.setItem("token", response.data.data.token);
			if (response.data.data.bank_account.length > 0) {
				sessionStorage.setItem(
					"account",
					response.data.data.bank_account[0].account_number
				);
				sessionStorage.setItem(
					"acc_name",
					response.data.data.bank_account[0].account_name
				);
			}

			sessionStorage.setItem("kyc", response.data.data.kyc_level);

			if (response.data.response_code === "USM003") {
				const appType = sessionStorage.getItem("applicationType");
				toast.success("Login Successful");
				var ciphertext = CryptoJS.AES.encrypt(
					JSON.stringify({
						token: response.data.data.token,
						kyc: response.data.data.kyc_level,
						user_id: response.data.data.id,
						account:
							response.data.data.bank_account.length > 0
								? response.data.data.bank_account[0]
										.account_number
								: "",
						acc_name:
							response.data.data.bank_account.length > 0
								? response.data.data.bank_account[0]
										.account_name
								: "",
					}),
					appType
				).toString();

				if (appType === "getrisave") {
					window.location.href = `${getrisaveUrl}?token=${ciphertext}`;
				} else {
					window.location.href = `${getripayUrl}?token=${ciphertext}`;
				}
			}

			return response.data;
		} catch (error) {
			console.log(error, "Error displayed Here!!!");
			if (error.response.data.response_code === "USM008") {
				toast.error("Token has expired, please login again");
				redirect("/");
			} else if (error.response.data.response_code === "USM009") {
				toast.error("Invalid Login code");
			} else if (error.response.data.response_code === "USM026") {
				toast.error(
					"Incorrect Answer. Please answer the next question"
				);
			} else if (error.response.data.response_code === "USM099") {
				toast.error(error?.response?.data?.response_message);
			} else {
				toast.error(
					error?.response?.data?.message[0] ||
						error?.response?.data?.response_message
				);
			}
			return rejectWithValue(error.response.data);
		}
	}
);

//Password reset
export const forgotPassword = createAsyncThunk(
	"forgot/forgotPassword",
	async (email, { rejectWithValue }) => {
		try {
			const response = await requestHandler.get(
				`/user/users/password/reset/token/${email}`
			);
			sessionStorage.setItem(
				"register_token",
				response.data.data.register_token
			);
			sessionStorage.setItem("user_id", response.data.data.user_id);

			if (response.data.success === true) {
				toast.success("Please check your mail to reset your password");
				redirect("/verify/password");
			}
			return response.data;
		} catch (error) {
			if (error.response.data.success === false) {
				toast.error("You are not registered, Please register");
			} else {
				toast.error("An error occurred. Please try again later");
			}

			return rejectWithValue(error.response.data);
		}
	}
);

export const verifyPassword = createAsyncThunk(
	"forgot/verifyPassword",
	async (token, { rejectWithValue }) => {
		try {
			const response = await requestHandler.get(
				`/user/users/${register_token}/password/token/${token}/verify`
			);

			if (response.data.success) {
				toast.success("Successful, Now set your new password");
				redirect("/reset-password");
			}
			return response.data;
		} catch (error) {
			if (error.response.data.response_message === "Token is not Valid") {
				toast.error("Invalid Token");
			} else if (
				error.response.data.response_message === "Token is used"
			) {
				toast.error("This code has been used");
				redirect("/forgot-password");
			}
			return rejectWithValue(error.response.data);
		}
	}
);

export const resetPassword = createAsyncThunk(
	"forgot/resetPassword",
	async ({ new_password, c_new_password }, { rejectWithValue, getState }) => {
		try {
			const { publicKey } = getState().login;

			let passPayload = getPayload(
				publicKey,
				{ new_password, c_new_password, user_id },
				version
			);
			const response = await requestHandler.post(
				`/user/users/password/change`,
				passPayload
			);

			if (response.data.success) {
				toast.success("Password changed successfully");
				redirect("/");
			}
			return response.data;
		} catch (error) {
			if (error.response.data.success === false) {
				toast.error("ERROR");
			}
			return rejectWithValue(error.response.data);
		}
	}
);
