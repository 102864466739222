/* eslint-disable react-hooks/exhaustive-deps */
import "./App.css";
import ClearCache from "./ClearCache";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-loading-skeleton/dist/skeleton.css";
import AppRouter from "./routing/routes";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { publicKey } from "./services/auth/loginService";

function App() {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(publicKey());
	}, []);

	return (
		<ClearCache>
			{({ loading, isLatestVersion, refreshCacheAndReload }) => {
				if (loading) return null;
				if (!loading && !isLatestVersion) {
					refreshCacheAndReload();
				}

				return (
					<>
						<AppRouter />
						<ToastContainer />
					</>
				);
			}}
		</ClearCache>
	);
}

export default App;
