import axios from "axios";
import { baseUrl } from "../config";

export const requestHandler = axios.create({
  baseURL: baseUrl,
  headers: {
    "Access-Control-Allow-Origin": "*",
    post: {
      "Content-Type": "application/json",
    },
  },
});

let accessToken;

if (typeof window !== "undefined") {
  accessToken = window.sessionStorage.getItem("token");
}

if (accessToken) {
    requestHandler.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${accessToken}`;
}

export const setToken = (token) => {
  if (token) {
    requestHandler.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${token}`;
  }
};
