export const baseUrl =
	process.env.REACT_APP_BASE_URL || "https://api.test.getripay.com/api/v3";
export const baseUrl2 =
	process.env.REACT_APP_BASE_URL2 || "https://api.test.getripay.com/api/v2";

export const getripayUrl =
	process.env.REACT_APP_GETRIPAY_URL || "https://testingv2.getripay.com.ng/";

export const getrisaveUrl =
	process.env.REACT_APP_GETRISAVE_URL || "http://getrisave.getrishop.com";

export const version = "v3";
