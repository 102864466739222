import { requestHandler } from "../../utils/requestHandler";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { baseUrl2 } from "../../config";

const register_token = sessionStorage.getItem("register_token");
const user_id = sessionStorage.getItem("user_id");
const email2 = sessionStorage.getItem("email2");

const redirect = (route) => {
  setTimeout(() => {
    window.location.href = route;
  }, 2500);
};

export const signUp = createAsyncThunk(
  "auth/signUp",
  async (data, { rejectWithValue }) => {
    sessionStorage.setItem("email2", data?.email);
    try {
      const response = await requestHandler.post(
        `/user/users/register/web`,
        data
      );
      if (response.data.response_code === "USM011") {
        toast.success(
          "Please check your email/phone for your verification code"
        );
        sessionStorage.setItem(
          "register_token",
          response.data.data.register_token
        );
        sessionStorage.setItem("user_id", response.data.data.user_id);
        redirect("/verify/phone");
      }
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const verifyPhoneToken = createAsyncThunk(
  "auth/verifyPhoneToken",
  async (token, { rejectWithValue }) => {
    try {
      const response = await requestHandler.get(
        `/user/users/${register_token}/verify_token_phone/${token}`
      );
      if (response.data.response_message === "Account created successfully") {
        toast.success("Account created successfully. Now, secure your account");
        redirect("/account-setup");
      } else if (response.data.response_code === "USM013") {
        toast.error("Token has expired, please resend");
      }
      return response.data;
    } catch (error) {
      if (error.response.data.response_code === "USM013") {
        toast.error("Token has expired, please resend");
      } else if (error.response.data.response_message === "Phone Verified") {
        toast.error("Phone has already been verified, please login");
        redirect("/");
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const resendPhoneToken = createAsyncThunk(
  "auth/resendPhoneToken",
  async (pap, { rejectWithValue }) => {
    try {
      const response = await requestHandler.get(
        `/user/users/resendotp/${register_token}/phone`
      );
      sessionStorage.setItem(
        "register_token",
        response.data.data.register_token
      );
      if (
        response.data.response_message ===
        "New Phone token Generated successful"
      ) {
        toast.success("An OTP has been sent to you via Email and SMS");
        window.location.reload(true);
      }

      return response.data;
    } catch (error) {
      if (error.response.data.response_message === "Not Exist") {
        toast.error("This phone number does not exist, please register");
        redirect("/register");
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const enableGoogleAuth = createAsyncThunk(
  "auth/enableGoogleAuth",
  async (email, { rejectWithValue }) => {
    try {
      const response = await requestHandler.post(
        `/user/users/enable_google_auth`,
        { email: email2 }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchQuestions = createAsyncThunk(
  "auth/fetchQuestions",
  async (_, { rejectWithValue }) => {
    try {
      const response = await requestHandler.get(`/user/security_questions`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const submitQuestions = createAsyncThunk(
  "auth/submitQuestions",
  async ({ password, items }, { rejectWithValue }) => {
    try {
      const response = await requestHandler.post(
        `/user/setsecurity/${user_id}`,
        { password, items }
      );
      toast.success("Security questions set successfully. Now login");
      redirect("/");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const signUpWithBvn = createAsyncThunk(
  "auth/signUpWithBvn",
  async ({ bvn, password }, { rejectWithValue }) => {
    try {
      const response = await requestHandler.post(
        `${baseUrl2}/user/register/validate-bvn`,
        {
          channel: "register_bvn_web",
          bvn_number: bvn,
          password: password,
        }
      );
      sessionStorage.setItem("trans_ref", response?.data?.data?.trans_ref);
      if (response.data.response_message === "Try again") {
        toast.error("Please try again");
      } else if (response.data.response_message !== "Try again") {
        toast.success(response.data.response_message);
        redirect("/register/bvn/verify");
      }
      return response.data;
    } catch (error) {
      sessionStorage.setItem("trans_ref", error?.response?.data?.trans_ref);
      if (error.response.data.response_code === "USM025") {
        toast.error("User already exists");
      } else if (error.response.data.response_code === "USM023") {
        toast.error("User with this BVN already exists");
      } else toast.error("Please enter a valid BVN");
      return rejectWithValue(error.response.data);
    }
  }
);

export const verifyBvnSignUp = createAsyncThunk(
  "auth/verifyBvnSignUp",
  async (otp_code, { rejectWithValue }) => {
    try {
      const response = await requestHandler.post(
        `${baseUrl2}/user/register/validate-bvn-otp`,
        {
          channel: "web",
          otp_code,
          trans_ref: sessionStorage.getItem("trans_ref"),
        }
      );

      toast.success("BVN registration successful. Now login");
      redirect("/");

      return response.data;
    } catch (error) {
      if (error.response.data.response_code === "USM024") {
        toast.error(
          "Something went wrong while generating user account. Please register again"
        );
      } else {
        toast.error(error.response.data.response_message);
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const validateBvn = createAsyncThunk(
  "auth/validateBvn",
  async ({ trans_ref, otp_code, channel }, { rejectWithValue }) => {
    try {
      const response = await requestHandler.post(
        `/user/register/validate-bvn-otp`,
        { trans_ref, otp_code, channel }
      );
      toast.success("Registration Successful. Now secure your account");
      redirect("/account-setup");
      return response.data;
    } catch (error) {
      if (error.response.data.response_code === "USM021") {
        toast.error("Something went wrong while validating bvn");
      } else if (error.response.data.response_code === "USM023") {
        toast.error("User already exists");
      } else if (error.response.data.response_code === "USM020") {
        toast.error("BVN validation failed");
      }
      return rejectWithValue(error.response.data);
    }
  }
);
