import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import logo from "../assets/images/g-logo.png";

const AccountSetUp = lazy(() => import("../pages/Auth/AccountSetup/index"));
const Login = lazy(() => import("../pages/Auth/Login/index"));
const ForgotPassword = lazy(() =>
	import("../pages/Auth/Login/ForgotPassword/ForgotPassword.js")
);
const ResetPassword = lazy(() =>
	import("../pages/Auth/Login/ForgotPassword/ResetPassword.js")
);
const VerifyPasswordOtp = lazy(() =>
	import("../pages/Auth/Login/ForgotPassword/VerifyPasswordOtp.js")
);
const VerifyGoogle = lazy(() => import("../pages/Auth/Login/VerifyGoogle.js"));
const VerifySecurity = lazy(() =>
	import("../pages/Auth/Login/VerifySecurity.js")
);
const Registration = lazy(() => import("../pages/Auth/Registration"));
const DownloadApp = lazy(() =>
	import("../pages/Auth/Registration/DownloadApp")
);
const RegisterWithBvn = lazy(() =>
	import("../pages/Auth/Registration/BVN/index.js")
);

const VerifyBvnSignUp = lazy(() =>
	import("../pages/Auth/Registration/BVN/VerifyBvnSignUp")
);

const VerifyPhone = lazy(() =>
	import("../pages/Auth/Registration/VerifyPhone.js")
);

export default function AppRouter() {
	return (
		<Router>
			<Suspense
				fallback={
					<div class="app-loader">
						<img src={logo} alt="loader" width="150" />
					</div>
				}
			>
				<Switch>
					<Route path="/" exact component={Login} />
					<Route path="/register" exact component={DownloadApp} />
					{/* <Route path="/register/bvn" exact component={RegisterWithBvn} />
          <Route
            path="/register/bvn/verify"
            exact
            component={VerifyBvnSignUp}
          /> */}
					<Route
						path="/account-setup"
						exact
						component={AccountSetUp}
					/>
					<Route path="/verify/phone" exact component={VerifyPhone} />
					<Route
						path="/verify/security-question"
						exact
						component={VerifySecurity}
					/>
					<Route
						path="/verify/google"
						exact
						component={VerifyGoogle}
					/>
					<Route
						path="/forgot-password"
						exact
						component={ForgotPassword}
					/>
					<Route
						path="/verify/password"
						exact
						component={VerifyPasswordOtp}
					/>
					<Route
						path="/reset-password"
						exact
						component={ResetPassword}
					/>
				</Switch>
			</Suspense>
		</Router>
	);
}
