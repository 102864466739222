import { createSlice } from "@reduxjs/toolkit";
import { forgotPassword, resetPassword, verifyPassword } from "../../../../services/auth/loginService";

const initialState = {
  forgotPass: {},
  verifyPass:{},
  resetPass:{}
};

export const forgotSlice = createSlice({
  name: "forgot",
  initialState,
  extraReducers: {
    //FORGOT PASSWORD
    [forgotPassword.pending]: (state) => {
      state.forgotPass.loading = true;
    },
    [forgotPassword.fulfilled]: (state, action) => {
      state.forgotPass.data = action.payload;
      state.forgotPass.loading = false;
      state.forgotPass.error = null
    },
    [forgotPassword.rejected]: (state, action) => {
      state.forgotPass.error = action.payload;
      state.forgotPass.loading = false;
    },

    //VERIFY--PASSWORD-OTP
    [verifyPassword.pending]: (state) => {
      state.verifyPass.loading = true;
    },
    [verifyPassword.fulfilled]: (state, action) => {
      state.verifyPass.data = action.payload;
      state.verifyPass.loading = false;
      state.verifyPass.error = null
    },
    [verifyPassword.rejected]: (state, action) => {
      state.verifyPass.error = action.payload;
      state.verifyPass.loading = false;
    },

     //RESET--PASSWORD
     [resetPassword.pending]: (state) => {
        state.resetPass.loading = true;
      },
      [resetPassword.fulfilled]: (state, action) => {
        state.resetPass.data = action.payload;
        state.resetPass.loading = false;
        state.resetPass.error = null
      },
      [resetPassword.rejected]: (state, action) => {
        state.resetPass.error = action.payload;
        state.resetPass.loading = false;
      },
  },
});

export const selectForgot = (state) => state.forgot.forgotPass;
export const selectVerify = (state) => state.forgot.verifyPass;
export const selectReset = (state) => state.forgot.resetPass;


export default forgotSlice.reducer;
