import { createSlice } from "@reduxjs/toolkit";
import {
	signIn,
	verifyGoogle,
	verifySecurityLogin,
	publicKey,
} from "../../../services/auth/loginService";

const initialState = {
	login: {},
	publicKey: null,
};

export const loginSlice = createSlice({
	name: "login",
	initialState,
	extraReducers: {
		[publicKey.fulfilled]: (state, action) => {
			state.publicKey = action.payload;
		},
		//SIGNUP
		[signIn.pending]: (state) => {
			state.login.loading = true;
		},
		[signIn.fulfilled]: (state, action) => {
			state.login.data = action.payload;
			state.login.loading = false;
			state.login.error = null;
		},
		[signIn.rejected]: (state, action) => {
			state.login.error = action.payload;
			state.login.loading = false;
		},

		//VERIFY--SECURITY-LOGIN
		[verifySecurityLogin.pending]: (state) => {
			state.login.loading = true;
		},
		[verifySecurityLogin.fulfilled]: (state, action) => {
			state.login = action.payload;
			state.login.loading = false;
			state.login.error = null;
		},
		[verifySecurityLogin.rejected]: (state, action) => {
			state.login.error = action.payload;
			state.login.loading = false;
		},

		//VERIFY--GOOGLE-LOGIN
		[verifyGoogle.pending]: (state) => {
			state.login.loading = true;
		},
		[verifyGoogle.fulfilled]: (state, action) => {
			state.login = action.payload;
			state.login.loading = false;
			state.login.error = null;
		},
		[verifyGoogle.rejected]: (state, action) => {
			state.login.error = action.payload;
			state.login.loading = false;
		},
	},
});

export const selectLogin = (state) => state.login.login;

export default loginSlice.reducer;
