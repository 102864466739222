import { createSlice } from "@reduxjs/toolkit";
import {
  enableGoogleAuth,
  fetchQuestions,
  resendPhoneToken,
  signUp,
  signUpWithBvn,
  submitQuestions,
  validateBvn,
  verifyBvnSignUp,
  verifyPhoneToken,
} from "../../services/auth/regService";

const initialState = {
  register: {},
  fetchQues: {},
  verifyPhone: {},
  submitQues: {},
  resendPhone: {},
  enableAuth: {},
  bvnSignup: {},
  verifySignupBvn:{},
  bvnValidate: {},
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: {
    //SIGNUP
    [signUp.pending]: (state) => {
      state.register.loading = true;
    },
    [signUp.fulfilled]: (state, action) => {
      state.register.data = action.payload;
      state.register.loading = false;
    },
    [signUp.rejected]: (state, action) => {
      state.register.error = action.payload;
      state.register.loading = false;
    },

    //VERIFY-PHONE
    [verifyPhoneToken.pending]: (state) => {
      state.verifyPhone.loading = true;
    },
    [verifyPhoneToken.fulfilled]: (state, action) => {
      state.verifyPhone.data = action.payload;
      state.verifyPhone.loading = false;
    },
    [verifyPhoneToken.rejected]: (state, action) => {
      state.verifyPhone.error = action.payload;
      state.verifyPhone.loading = false;
    },

    //RESEND PHONE TOKEN

    [resendPhoneToken.pending]: (state) => {
      state.resendPhone.loading = true;
    },
    [resendPhoneToken.fulfilled]: (state, action) => {
      state.resendPhone.loading = false;
    },
    [resendPhoneToken.rejected]: (state, action) => {
      state.resendPhone.loading = false;
    },

    //SECURITY QUESTIONS
    [fetchQuestions.pending]: (state) => {
      state.fetchQues.loading = true;
    },
    [fetchQuestions.fulfilled]: (state, action) => {
      state.fetchQues.data = action.payload.data;
      state.fetchQues.loading = false;
    },
    [fetchQuestions.rejected]: (state, action) => {
      state.fetchQues.error = action.payload;
      state.fetchQues.loading = false;
    },

    //SUBMIT QUES
    [submitQuestions.pending]: (state) => {
      state.submitQues.loading = true;
    },
    [submitQuestions.fulfilled]: (state, action) => {
      state.submitQues.data = action.payload.data;
      state.submitQues.loading = false;
    },
    [submitQuestions.rejected]: (state, action) => {
      state.submitQues.error = action.payload;
      state.submitQues.loading = false;
    },

    //Enable google auth
    [enableGoogleAuth.pending]: (state) => {
      state.enableAuth.loading = true;
    },
    [enableGoogleAuth.fulfilled]: (state, action) => {
      state.enableAuth.data = action.payload.data;
      state.enableAuth.loading = false;
    },
    [enableGoogleAuth.rejected]: (state, action) => {
      state.enableAuth.error = action.payload;
      state.enableAuth.loading = false;
    },

    // Sign Up with BVN
    [signUpWithBvn.pending]: (state) => {
      state.bvnSignup.loading = true;
    },
    [signUpWithBvn.fulfilled]: (state, action) => {
      state.bvnSignup.data = action.payload.data;
      state.bvnSignup.loading = false;
    },
    [signUpWithBvn.rejected]: (state, action) => {
      state.bvnSignup.error = action.payload;
      state.bvnSignup.loading = false;
    },

    //Verify  Sign Up with BVN
    [verifyBvnSignUp.pending]: (state) => {
      state.verifySignupBvn.loading = true;
    },
    [verifyBvnSignUp.fulfilled]: (state, action) => {
      state.verifySignupBvn.data = action.payload.data;
      state.verifySignupBvn.loading = false;
    },
    [verifyBvnSignUp.rejected]: (state, action) => {
      state.verifySignupBvn.error = action.payload;
      state.verifySignupBvn.loading = false;
    },

    // Validate BVN
    [validateBvn.pending]: (state) => {
      state.bvnValidate.loading = true;
    },
    [validateBvn.fulfilled]: (state, action) => {
      state.bvnValidate.data = action.payload.data;
      state.bvnValidate.loading = false;
    },
    [validateBvn.rejected]: (state, action) => {
      state.bvnValidate.error = action.payload;
      state.bvnValidate.loading = false;
    },
  },
});

export const selectRegister = (state) => state.auth.register;
export const selectQuestions = (state) => state.auth.fetchQues;
export const selectVerifyPhone = (state) => state.auth.verifyPhone;
export const selectSubmitQues = (state) => state.auth.submitQues;
export const selectEnableAuth = (state) => state.auth.enableAuth;
export const selectSignupBvn = (state) => state.auth.bvnSignup;
export const selectVerifySignupBvn = (state) => state.auth.verifySignupBvn;
export const selectBvnValidate = (state) => state.auth.bvnValidate;

export default authSlice.reducer;
